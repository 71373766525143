<template>
  <div class="flex">
    <a-form layout="horizontal" :model="formState" :rules="rules" ref="formRefFilter">
      <a-form-item label="Departamento" class="mb-0 inline-block" name="search">
        <a-select
          v-model:value="formState.departamento"
          style="width: 200px"
          @change="handleDepartamento"
        >
          <a-select-option value="">TODOS LOS DEPARTAMENTOS</a-select-option>
          <a-select-option
            :value="departamento.departamento"
            v-for="departamento in departamentos"
            :key="departamento.departamento"
          >
            {{ departamento.departamento }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Provincia" class="mb-0 inline-block ml-5" name="search">
        <a-select
          v-model:value="formState.provincia"
          style="width: 200px"
          @change="handleProvincia"
        >
          <a-select-option value="">TODAS LAS PROVINCIAS</a-select-option>
          <a-select-option
            :value="provincia.provincia"
            v-for="provincia in provincias"
            :key="provincia.provincia"
          >
            {{ provincia.provincia }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Distrito" class="mb-0 inline-block ml-8" name="search">
        <a-select v-model:value="formState.distrito" style="width: 200px">
          <a-select-option value="">TODOS LOS DISTRITOS</a-select-option>
          <a-select-option
            :value="distrito.distrito"
            v-for="distrito in distritos"
            :key="distrito.distrito"
          >
            {{ distrito.distrito }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <button @click="applyFilters" type="submit" class="text-center px-3">
      <div class="text-3xl text-blue-400">
        <SearchOutlined />
      </div>
      <p>Buscar</p>
    </button>

    <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
      <div
        class="text-3xl"
        :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-300']"
      >
        <ClearOutlined />
      </div>
      <p>Limpiar filtros</p>
    </button>
  </div>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["fetchUbigeo"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const departamentos = ref([]);
    const provincias = ref([]);
    const distritos = ref([]);

    const formState = reactive({
      departamento: store.getters["ubigeo/departamento"],
      provincia: store.getters["ubigeo/provincia"],
      distrito: store.getters["ubigeo/distrito"],
      search: store.getters["ubigeo/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    onMounted(() => {
      getAllDepartamentos();
      getProvincias(formState.departamento);
      getDistritos(formState.provincia);
    });

    const getProvincias = (filtro) => {
      EstablecimientoApi.getAllProvincias(filtro)
        .then((response) => {
          provincias.value = response.data;
          formState.provincia = "";
          formState.distrito = "";

          getDistritos(formState.provincia);
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = (filtro) => {
      EstablecimientoApi.getAllDistritos({ filtro })
        .then((response) => {
          distritos.value = response.data;
          formState.distrito = "";
        })
        .catch((err) => console.log(err));
    };

    const getAllDepartamentos = () => {
      EstablecimientoApi.getAllDepartamentos()
        .then((response) => {
          departamentos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const handleDepartamento = (value) => {
      getProvincias(value);
    };

    const handleProvincia = (value) => {
      getDistritos(value);
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { departamento, provincia, distrito, search } = toRaw(formState);
          // console.log(filter, search);
          store.dispatch("ubigeo/setDepartamento", departamento);
          store.dispatch("ubigeo/setProvincia", provincia);
          store.dispatch("ubigeo/setDistrito", distrito);
          store.dispatch("ubigeo/setSearch", search);
          emit("fetchUbigeo");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.departamento = "";
      formState.provincia = "";
      formState.distrito = "";
      formState.search = "";

      store.dispatch("ubigeo/setDepartamento", "");
      store.dispatch("ubigeo/setProvincia", "");
      store.dispatch("ubigeo/setDistrito", "");
      store.dispatch("ubigeo/setSearch", "");
      store.dispatch("ubigeo/cleanFilters");
      emit("fetchUbigeo");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled,
      departamentos,
      provincias,
      distritos,
      handleDepartamento,
      handleProvincia
    };
  }
};
</script>
