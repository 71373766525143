export const columns = [
  {
    title: "N°",
    key: "index",
    width: "4%",
    slots: { customRender: "rangepages" }
  },
  {
    title: "Ubigeo INEI",
    dataIndex: "idubigeoinei",
    key: "idubigeoinei"
  },
  {
    title: "Ubigeo RENIEC",
    dataIndex: "idubigeoreniec",
    key: "idubigeoreniec"
  },
  {
    title: "Departamento",
    dataIndex: "departamento",
    key: "departamento"
  },
  {
    title: "Provincia",
    dataIndex: "provincia",
    key: "provincia"
  },
  {
    title: "Distrito",
    dataIndex: "distrito",
    key: "distrito"
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" }
  }
];
