import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAll: (payload) => {
    const { departamento, provincia, distrito, search } = payload;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_mas_ubigeo",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "departamento|provincia|distrito",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${departamento}%|%${provincia}%|%${distrito}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "idubigeoinei|idubigeoreniec",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
