<template>
  <a-page-header @back="goBack" title="UBIGEO" :breadcrumb="{ routes }" class="px-0" />

  <div class="flex mb-4">
    <!--------------- Filtros--------------->
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-left ml-2">FILTROS DE UBIGEO</legend>
      <UbigeoFiltros @fetchUbigeo="fetchUbigeo" />
    </fieldset>
  </div>

  <a-table
    :columns="columns"
    :data-source="data"
    size="small"
    :pagination="false"
    rowKey="idubigeoinei"
    :loading="isLoading"
    :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
  >
    <template #rangepages="{ index }">
      <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
    </template>

    <template #activo="{ text: activo }">
      <span>
        <a-tag :color="activo === '1' ? 'green' : 'volcano'">
          {{ activo === "1" ? "Activado" : "Desactivado" }}
        </a-tag>
      </span>
    </template>
  </a-table>

  <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
    <!-- show-size-changer -> para agregar el @limit (numero de datos a traer) -->
    <div class="flex">
      <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />
      <!-- @showSizeChange="onShowSizeChange" -->

      <button class="ml-4" @click="fetchProductoraServicios">
        <ReloadOutlined />
      </button>
    </div>

    <p>Total de registros: {{ totalDocs }}</p>
  </footer>
</template>

<script>
import { ref, watch, reactive, toRaw, onMounted } from "vue";
import { columns } from "./utilsUbigeo";
import { useStore } from "vuex";
import router from "@/router";
import { ReloadOutlined } from "@ant-design/icons-vue";
import UbigeoApi from "@/api/ubigeo";
import UbigeoFiltros from "./UbigeoFiltros.vue";

export default {
  name: "Productora",
  components: {
    UbigeoFiltros,
    ReloadOutlined
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const curretPage = ref(1);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const data = ref([]);
    const isLoading = ref(false);
    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const goBack = () => router.back();

    // ------- breadcrumbs --------
    const routes = [
      {
        path: "app",
        breadcrumbName: "Ubigeo"
      },
      {
        path: "/ubigeo",
        breadcrumbName: "Ver Ubigeo"
      }
    ];

    // ------- trae datos de la api --------
    const fetchUbigeo = () => {
      const departamento = store.getters["ubigeo/departamento"];
      const provincia = store.getters["ubigeo/provincia"];
      const distrito = store.getters["ubigeo/distrito"];
      const search = store.getters["ubigeo/search"];
      const currentPage = toRaw(curretPage.value);

      isLoading.value = true;
      UbigeoApi.getAll({ currentPage, departamento, provincia, distrito, search })
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      fetchUbigeo();
    });

    // -------- trae datos al cambiar de pagina --------
    watch(curretPage, () => {
      fetchUbigeo();
    });

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    return {
      routes,
      columns,
      data,
      isVisible,
      isDisabled,
      pageSize,
      curretPage,
      isLoading,
      totalDocs,
      goBack,
      fetchUbigeo
    };
  }
};
</script>
